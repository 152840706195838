import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-8/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-4/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);



const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-orange-500`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-1 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  subheading = "Vi finns på Lignells väg 1, 136 49 Vega",
  heading = (
    <>
      Hitta till oss!
    </>
  ),
  description = "qwe",
  primaryButtonText = "Kontakta oss",
  primaryButtonUrl = "/kontakt",
//   imageSrc = "https://cdn.pixabay.com/photo/2013/07/12/15/51/van-150423_960_720.png",
  buttonRounded = true,
  imageShadow = false,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
        
        <iframe tw="justify-center py-10 w-full" height={400} src="https://maps.google.com/maps?q=Lignells vägen 1,%20136%2049%Vega&t=&z=13&ie=UTF8&iwloc=&output=embed" ></iframe> 
          
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Subheading>{subheading}</Subheading>
            <Description style={{color: 'rgb(124 139 161 / var(--tw-text-opacity))'}}>
            ÖPPETTIDER<br/>

            <br/>
          
            
            Måndag-Torsdag: 09.00-18.00<br/>
            Fredag: 09.00-17.00<br/>
            Lördag: 10.00-14.00<br/>
            </Description>
            
       
            <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
